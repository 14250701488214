import { ContentfulClientApi, createClient, Entry, Asset } from "contentful";

type Link<T extends string> = {
  sys?: {
    type: "Link";
    linkType: T;
    id: string;
  };
};

type ServicesContent = {
  serviceName: string;
  serviceLogo?: Asset;
};

export type TestimonialContent = {
  company: string;
  companyRepresentative: string;
  testimonial: string;
  logo: Asset;
  show: boolean;
  rank: number;
};

type EntryLink = Link<"Entry">;

export type TrotsMuurTileContent = {
  title: string;
  id: string;
  summary?: string;
  body?: string;
  portfolioImage: Asset;
  projectImage?: Asset;
  testimonial?: Entry<TestimonialContent>;
  services?: Entry<ServicesContent>[];
  rank?: number;
  videoEmbedCode?: string;
  videoUrl?: string;
  expertEmployee: EntryLink;
  socialImage: Asset;
  projectDate: string;
};

let apiUrl: URL | null = null;

if (process.env.REACT_APP_CONTENTFUL_API_URL) {
  apiUrl = new URL(process.env.REACT_APP_CONTENTFUL_API_URL);
}

const tileContentType =
  process.env.REACT_APP_CONTENTFUL_TILE_CONTENT_TYPE_ID || "projectTileDetail";

export const getClient = (): ContentfulClientApi =>
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID!,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_KEY!,
    host: apiUrl?.host,
    onBeforeRequest: function (config) {
      if(apiUrl != null) {
        /* To make the Contentful library pass cookies to our proxy. */
        config.withCredentials = true;
      }

      return config;
    },
  });
/* eslint-enable @typescript-eslint/no-non-null-assertion */

export default getClient;

export const getTiles = (): Promise<Entry<TrotsMuurTileContent>[]> => {
  return getClient()
    .getEntries<TrotsMuurTileContent>({ content_type: tileContentType })
    .then((response) => response.items)
    .catch((error) => {
      const details = JSON.parse(error.message);

      if (details.status == 401 && apiUrl) {
        const authUrl = new URL(apiUrl);
        authUrl.pathname = "/auth/login";
        authUrl.searchParams.append("redirectUrl", window.location.href);
        window.location.href = authUrl.toString();
      }

      return error;
    });
};
