import React from "react";
import { RouteComponentProps } from "react-router";
import ProjectDetail from "./ProjectDetail";

type ProjectDetailRouteProps = RouteComponentProps<{ id: string }>;

const ProjectDetailRoute: React.FC<ProjectDetailRouteProps> = ({
  match,
  history,
}) => {
  const nextPath = (path: string) => {
    history.push("/filter/" + path);
  };

  return <ProjectDetail projectId={match.params.id} navigate={nextPath} />;
};
export default ProjectDetailRoute;
