import React from "react";
import { TrotsMuurTileContent } from "./Client";

type tileData = { tileContent: TrotsMuurTileContent };

const Tile: React.FC<tileData> = ({ tileContent }) => {
  return (
    <div>
      <h1>{tileContent.title}</h1>

      {/* <h2>{tileContent.companyName}</h2> */}

      {tileContent.projectImage && (
        <img
          src={"http:" + tileContent.projectImage.fields.file.url}
          alt={"PIC"}
          width="200"
          height="200"
        />
      )}
      <p>Startdatum project: {tileContent.projectDate}</p>
    </div>
  );
};

export default Tile;
