import { RouteComponentProps } from "react-router";
import React from "react";
import ProjectOverview from "./ProjectOverview";

type ProjectOverviewRouteProps = RouteComponentProps<{ service: string }>;

const ProjectOverviewRoute: React.FC<ProjectOverviewRouteProps> = ({
  history,
  match,
}) => {
  const nextPath = (path: string) => {
    history.push("/detail/" + path);
  };
  return <ProjectOverview navigate={nextPath} match={match.params.service} />;
};

export default ProjectOverviewRoute;
