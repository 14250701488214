import React from "react";
import "./App.css";
import ProjectOverviewRoute from "./ProjectOverviewRoute";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ProjectDetailRoute from "./ProjectDetailRoute";

const App: React.FC = () => (
  <Router>
    <div className="App">
      <Route path={"/"} exact component={ProjectOverviewRoute} />
      <Route path={"/filter/:service"} exact component={ProjectOverviewRoute} />
      <Route path={"/detail/:id"} exact component={ProjectDetailRoute} />
    </div>
  </Router>
);

export default App;
