import React, { useEffect, useState } from "react";
import { Entry } from "contentful";
import { getTiles, TrotsMuurTileContent } from "./Client";
import Tile from "./Tile";
import { Ul } from "./ProjectDetail";

type ProjectOverviewProps = {
  navigate: (path: string) => void;
  match: string | undefined;
};

const ProjectOverview: React.FC<ProjectOverviewProps> = ({
  navigate,
  match,
}) => {
  const [tiles, setTiles] = useState<Entry<TrotsMuurTileContent>[]>([]);

  const componentTitle = "De trotsmuur";

  useEffect(() => {
    getTiles().then((tilesObj) => {
      setTiles((list) => [...list, ...[]]);

      const tilesArrray: Entry<TrotsMuurTileContent>[] = [];
      if (match !== undefined) {
        for (let i = 0; i < tilesObj.length; i++) {
          tilesObj[i].fields.services?.map((data) => {
            if (data.fields.serviceName === match) {
              tilesArrray.push(tilesObj[i]);
            }
          });
        }
        setTiles((list) => [...list, ...tilesArrray]);
      } else {
        setTiles((list) => [...list, ...tilesObj]);
      }
    });
  }, []);

  return (
    <div>
      <div>
        <h1>{componentTitle}</h1>
        <h2>Our Big Goal</h2>

        <p>
          In 2030 werken wij tegelijkertijd aan 100 toon aangevende applicaties
          waar we trots op zijn!
        </p>
      </div>
      {tiles.length === 0 && <h1>No tiles found with {match} as a service</h1>}

      <Ul>
        {tiles.map((data) => (
          <li
            onClick={() => navigate(data.fields.title)}
            key={data.fields.title}
          >
            <Tile tileContent={data.fields} />
          </li>
        ))}
      </Ul>
    </div>
  );
};

export default ProjectOverview;
