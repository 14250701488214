import React, { useEffect, useState } from "react";
import { getTiles, TrotsMuurTileContent } from "./Client";
import { Entry } from "contentful";
import styled from "styled-components";
// flex-wrap: wrap;
const Row = styled.div`
  display: flex;
  justify-content: center;
`;

export const Ul = styled.ul`
  list-style-type: none;
`;

type ProjectDetailProps = {
  projectId: string;
  navigate: (path: string) => void;
};

const ProjectDetail: React.FC<ProjectDetailProps> = ({
  projectId,
  navigate,
}) => {
  const [content, setContent] = useState<Entry<TrotsMuurTileContent>>();

  useEffect(() => {
    checkRightProject();
  }, []);

  const checkRightProject = () => {
    getTiles().then((data) => {
      setContent(data.find((array) => array.fields.title === projectId));
    });
  };

  const filterOnService = (serviceName: string) => {
    navigate(serviceName);
  };

  if (!content) {
    return null;
  }

  return (
    <div>
      <h1>{content.fields.title}</h1>
      <img
        src={"http:" + content.fields.projectImage?.fields.file.url}
        alt={"loading"}
        width="250"
        height="260"
      />
      <p>{content.fields.testimonial?.fields?.testimonial}</p>
      {content.fields.summary != null && <p>{content.fields.summary}</p>}
      <hr></hr>
      <Ul>
        {content.fields.services?.map((data) => (
          <li
            onClick={() => filterOnService(data.fields.serviceName)}
            key={data.fields?.serviceName}
          >
            <Row key={data.fields?.serviceName}>
              <p key={data.fields?.serviceName}>{data.fields?.serviceName}</p>
              <img
                src={"http:" + data.fields?.serviceLogo?.fields.file.url}
                alt={"PIC"}
                width="80"
                height="80"
              />
            </Row>
          </li>
        ))}
      </Ul>
      <hr></hr>
    </div>
  );
};
export default ProjectDetail;
